body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header, main, footer {
  flex-shrink: 0;
}
.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  padding-right: 40px;
}

.App-logo {
  height: 210px;
  transition: height 0.3s ease;
}

.App-nav ul {
  list-style: none;
  display: flex;
  gap: 80px;
  padding-right: 150px;
  transition: padding-right 0.5 ease;
  margin: 0;
}

.App-nav ul li {
  flex: 1; /* Ensure each item takes equal space */
  text-align: center;
}

.App-nav a {
  text-decoration: none;
  color: black;
  font-size: 2.5em;
  font-weight: 500;
}

main {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

main img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.App-footer {
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  padding: 10px;
  font-size: 0.9em;
  color: #555;
  text-align: center;
  flex-shrink: 0;    
  margin-top: auto;
}

/* Styles specific to the "Home" page */
.home {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  gap: 20px;
}

.home .images img {
  flex: 1; /* Allow images to grow and shrink */
  max-width: 48%; /* Ensure images are not too large */
  gap: 20px;
  flex-wrap: wrap;
}

.home .text {
  text-align: left;
}

.home .text h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.home .text p {
  margin-bottom: 10px;
}

/* Styles specific to the "About" page */
.about {
  background-color: #ffffff;
  padding: 20px;
  margin: 20px auto; /* Added margin for the About page */
  max-width: 1200px;
}

.about-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.about-text {
  margin-bottom: 20px;
}

.about-image {
  width: 100%;
  height: auto;
}

/* Styles specific to the "Author" page */
.author {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  gap: 20px;
}

.author .images {
  flex: 1;
  text-align: center;
}

.author .images img {
  width: 100%;
  max-width: 300px; /* Ensure image is not too large */
  height: auto;
}

.author .text {
  flex: 2;
  font-size: 1.1em; /* Slightly larger text for the Author page */
}

.author .text h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.author .text p {
  margin-bottom: 10px;
}

/* Styles specific to the "Books" page */
.books {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  gap: 20px;
  align-items: stretch;
  row-gap: 50px;
  font-size: 1.1em;
}

.books2 {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  gap: 20px;
  align-items: stretch;
  row-gap: 50px;
  font-size: 1.1em;
}

/* Responsive adjustments */
@media (max-width: 1300px) {
  .App-header {
    flex-direction: column;
    text-align: center;
  }

  .App-logo {
    height: 150px;
  }

  .App-nav ul {
    gap: 20px;
    padding-right: 0;
  }

  .App-nav a {
    font-size: 2em;
  }

  .author,
  .home,
  .books,
  .books2 {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .books .images-text {
    flex-direction: column; /* Stack images and text vertically on smaller screens */
  }
}

@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
  }

  .App-logo {
    height: 100px;
  }

  .App-nav ul {
    gap: 10px;
  }

  .App-nav a {
    font-size: 1.5em;
  }

  .author,
  .home,
  .books {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .books .images-text {
    flex-direction: column; /* Stack images and text vertically on smaller screens */
  }

  .home .images img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .App-header {
    flex-direction: column;
  }

  .App-logo {
    height: 80px;
  }

  .App-nav ul {
    flex-wrap: wrap;
    gap: 10px;
  }

  .App-nav ul li {
    flex: 1 1 calc(50% - 10px); /* Ensures 2 items per row */
    box-sizing: border-box;
  }

  .App-nav a {
    font-size: 1.2em;
  }

  .author,
  .home,
  .books {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .books .images-text {
    flex-direction: column; /* Stack images and text vertically on smaller screens */
  }

  .home .images img {
    max-width: 100%;
  }
}
